<template>
  <DefaultLoader :loading="isNowLoading">
    <section
      class="signin-inner my-3 my-lg-0 shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500"
    >
      <div class="text-center text-md-center mb-4 mt-md-0">
        <h1 class="mb-0 h3">
          <router-link to="/">
            <img src="@/assets/logo.png" height="34" alt="Paidmoolah Logo" />
          </router-link>
          <br />
          Control Panel
        </h1>
      </div>
      <LoginForm />
    </section>
  </DefaultLoader>
</template>

<script lang="ts">
import LoginForm from "@/components/LoginForm.vue";
import DefaultLoader from "@/components/loader/DefaultLoader.vue";
import { provide, ref } from "vue";
import store from "@/store";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

// import store from "@/store";
export default {
  name: "Login",
  components: { DefaultLoader, LoginForm },
  setup() {
    const isNowLoading = ref(false);

    provide("loginLoading", (value: boolean) => {
      isNowLoading.value = value;
    });

    return {
      isNowLoading,
    };
  },
  beforeRouteEnter(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ): void {
    if (store.getters.userIsLoggedIn) {
      next((to.query.redirect as string) ?? "/");
      return;
    }

    next();
  },
};
</script>

<style scoped></style>
