
import LoginForm from "@/components/LoginForm.vue";
import DefaultLoader from "@/components/loader/DefaultLoader.vue";
import { provide, ref } from "vue";
import store from "@/store";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

// import store from "@/store";
export default {
  name: "Login",
  components: { DefaultLoader, LoginForm },
  setup() {
    const isNowLoading = ref(false);

    provide("loginLoading", (value: boolean) => {
      isNowLoading.value = value;
    });

    return {
      isNowLoading,
    };
  },
  beforeRouteEnter(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ): void {
    if (store.getters.userIsLoggedIn) {
      next((to.query.redirect as string) ?? "/");
      return;
    }

    next();
  },
};
