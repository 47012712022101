<template>
  <form action="#" class="mt-4" @submit.prevent="handleLogin">
    <template v-if="requireOtp">
      <div class="form-group mb-4">
        <label for="otp">One time OTP</label>
        <InputWrapper :errors="formError.otp">
          <span class="input-group-text">
            <span class="fas fa-user-shield"></span>
          </span>
          <input
            v-model="formData.otp"
            type="number"
            class="form-control px-1"
            placeholder="******"
            id="otp"
            required
          />
        </InputWrapper>
      </div>
    </template>
    <template v-else>
      <!-- Form -->
      <div class="form-group mb-4">
        <label for="email">Your Username</label>
        <InputWrapper :errors="formError.email">
          <span class="input-group-text" id="basic-addon1">
            <span class="fas fa-envelope"></span>
          </span>
          <input
            v-model="formData.email"
            type="text"
            class="form-control px-1"
            placeholder="email@email.com"
            id="email"
            autofocus
            required
          />
        </InputWrapper>
      </div>
      <!-- End of Form -->
      <div class="form-group">
        <!-- Form -->
        <div class="form-group mb-4">
          <label for="password">Your Password</label>
          <InputWrapper :errors="formError.password">
            <span class="input-group-text" id="basic-addon2">
              <span class="fas fa-unlock-alt"></span>
            </span>
            <input
              v-model="formData.password"
              type="password"
              placeholder="Password"
              class="form-control px-1"
              id="password"
              required
            />
          </InputWrapper>
        </div>
        <!-- End of Form -->
        <div class="d-flex justify-content-between align-items-center mb-4">
          <div>
            <router-link to="forgot_p" class="small text-right">Lost password?</router-link>
          </div>
        </div>
      </div>
    </template>
    <button type="submit" class="btn w-100 btn-dark">Sign in</button>
  </form>
</template>

<script lang="ts">
import { ref, inject, defineComponent } from "vue";
import { apiPost } from "@/api";
import { Toast } from "@/utils/notify";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import InputWrapper from "@/components/InputWrapper.vue";
import { UserActionTypes } from "@/store/modules/user/actions";

export default defineComponent({
  name: "LoginForm",
  components: { InputWrapper },
  setup() {
    const formData = ref({
      email: "",
      password: "",
      otp: undefined,
    });
    const formError = ref({});
    const rememberMe = ref(false);
    const loadingContext = inject("loginLoading") as (value: boolean) => void;
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const requireOtp = ref(false);

    async function handleLogin() {
      loadingContext(true);
      formError.value = {};
      try {
        const response = await apiPost(
          "admin/user-account/sign-in",
          formData.value
        );
        if (response.status === 201) {
          requireOtp.value = true;
        } else {
          await store.dispatch(UserActionTypes.Login, response.data.data);
          await router.push((route.query.redirect as string) ?? "/");
        }
      } catch (e) {
        console.log("log error____", e);

        formError.value = e?.response?.data?.i ?? {};
        Toast.fire({
          icon: "warning",
          title: e.message,
        }).then();
      }
      loadingContext(false);
    }

    return {
      formData,
      formError,
      rememberMe,
      requireOtp,
      handleLogin,
    };
  },
});
</script>

<style scoped></style>
